import React from "react";
import styled from "styled-components";

const Span = styled.span`
	color: #333333;
	font-weight: 700;
	:after {
		content: " ";
	}
`;

const Emphasis = ({ children }) => <Span>{children}</Span>;

export default Emphasis;
