import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Input from "./Input";
import { FormContext } from "./Form";
import colors from "../../styles/colors";

const Container = styled.div``;

const Label = styled.label`
	display: block;
	margin-bottom: 6px;
	color: ${({ hasError, focused }) => {
		if (hasError) return "#f44336";
		else if (focused) return colors.primaryColor;
		else return "#808080";
	}};
`;

const Error = styled.div`
	margin-top: 8px;
	color: #f44336;
	font-size: 13px;
	font-weight: 400;
	:empty {
		display: none;
	}
`;

const Field = ({
	label,
	required,
	validate: customValidation,
	value,
	...other
}) => {
	const { validateOnFormSubmit = () => () => {} } = useContext(FormContext);

	const [errorMsg, setErrorMsg] = useState();

	const [touched, setTouched] = useState(false);
	const touch = () => setTouched(true);

	const [focused, setFocused] = useState(false);

	const validate = () => {
		const valid =
			(required && !value) ||
			(customValidation && !customValidation(value))
				? false
				: true;
		setErrorMsg(valid ? null : `Please enter a valid ${label}`);
		return valid;
	};

	useEffect(() =>
		validateOnFormSubmit(() => {
			touch();
			return validate();
		})
	);

	const handleFocus = () => {
		touch();
		setFocused(true);
	};

	const handleBlur = () => {
		validate();
		setFocused(false);
	};

	const shownErrorMsg = touched ? errorMsg : undefined;

	return (
		<Container>
			<Label hasError={shownErrorMsg ? true : false} focused={focused}>
				{label}
				{required ? "*" : ""}
			</Label>
			<Input
				{...other}
				value={value}
				hasError={shownErrorMsg ? true : false}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<Error>{shownErrorMsg}</Error>
		</Container>
	);
};

export default Field;
