import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Container = styled.div`
	width: 100%;
	overflow: hidden;
	border: 1px solid ${colors.borderColor};
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	@media only screen and (max-width: 800px) {
		border-radius: 7px;
	}
`;

const Image = styled.img`
	display: block;
	width: 100%;
	margin: 0 auto;
	${({ imageStyles }) => imageStyles}
`;

const Caption = styled.span`
	display: block;
	margin-top: 15px;
	font-family: "Special Elite", sans-serif;
	line-height: 1.5;
	:empty {
		display: none;
	}
`;

const FancyImage = ({ src, alt, imageStyles, className, caption }) => (
	<>
		<Container className={className}>
			<Image src={src} alt={alt} imageStyles={imageStyles} />
		</Container>
		<Caption>{caption}</Caption>
	</>
);

export default FancyImage;
