import React from "react";
import styled from "styled-components";

const typeColors = {
	success: {
		text: "#4caf50",
		background: "#edf7ed",
		border: "#c9e7ca"
	},
	error: {
		text: "#f44336",
		background: "#feeceb",
		border: "#fcc6c2"
	}
};

const Container = styled.div`
	width: 100%;
    padding: 10px 15px;
	background-color: ${({ type }) => typeColors[type].background}
	border: 1px solid ${({ type }) => typeColors[type].border};
	border-radius: 7px;
	color: ${({ type }) => typeColors[type].text};
	font-weight: 400;
	@media only screen and (max-width: 800px) {
		padding: 8px 12px;
	}
`;

const Message = styled.span``;

const Alert = ({ type, children }) => (
	<Container type={type}>
		<Message>{children}</Message>
	</Container>
);

export default Alert;
