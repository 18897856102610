import styled from "styled-components";

const Section = styled.div`
	margin: 70px 0;
	:last-child {
		margin-bottom: 100px;
	}
	@media only screen and (max-width: 1300px) {
		:last-child {
			margin-bottom: 90px;
		}
	}
	@media only screen and (max-width: 1000px) {
		margin: 50px 0;
		:last-child {
			margin-bottom: 70px;
		}
	}
	@media only screen and (max-width: 800px) {
		margin: 40px 0;
		:last-child {
			margin-bottom: 60px;
		}
	}
	@media only screen and (max-width: 600px) {
		:last-child {
			margin-bottom: 50px;
		}
	}
	@media only screen and (max-width: 450px) {
		margin: 30px 0;
		:last-child {
			margin-bottom: 40px;
		}
	}
`;

export default Section;
