import React from "react";
import ProjectExcerpt from "../components/excerpts/ProjectExcerpt";
import projects from "../data/projects";

const Client = ({ match }) => {
	const requestedClient = match.params.client_name;
	const clientFound = projects.find(
		project => project.name === requestedClient
	);
	/*
     {
         name:
         title:
     }
    */

	return (
		<>
			<ProjectExcerpt {...clientFound} />
		</>
	);
};

export default Client;
