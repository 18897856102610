import React, { useContext } from "react";
import styled from "styled-components";
import Button from "../Button";
import { StatusContext } from "./EmailForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const FormButton = styled(Button)`
	margin-top: 30px;
`;

const SubmitButton = () => {
	const { status } = useContext(StatusContext);
	return (
		<FormButton
			as="button"
			type="submit"
			name="submit"
			disabled={status === "submitting"}
		>
			Send message
			{status === "submitting" ? (
				<>
					{" "}
					<FontAwesomeIcon icon={faSpinner} spin size="xs" />
				</>
			) : (
				""
			)}
		</FormButton>
	);
};

export default SubmitButton;
