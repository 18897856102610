import React from "react";
import styled from "styled-components";

import rushBookmarkGrouchoFront from "../../images/projects/TextbookRush/rush_bookmark_groucho_front_2x.jpg";
import rushBookmarkGrouchoBack1 from "../../images/projects/TextbookRush/rush_bookmark_groucho_back1_2x.jpg";
import rushBookmarkGrouchoBack2 from "../../images/projects/TextbookRush/rush_bookmark_groucho_back2_2x.jpg";
import rushBookmarkXFront1 from "../../images/projects/TextbookRush/rush_bookmark_x_front1_2x.jpg";
import rushBookmarkXFront2 from "../../images/projects/TextbookRush/rush_bookmark_x_front2_2x.jpg";
import rushBookmarkXBack from "../../images/projects/TextbookRush/rush_bookmark_x_back_2x.jpg";
import rushBookmarkNight from "../../images/projects/TextbookRush/rush_bookmark_night_2x.jpg";
import rushBookmarkPlanA from "../../images/projects/TextbookRush/rush_bookmark_plan-a_2x.jpg";
import rushBookmarkSeeds from "../../images/projects/TextbookRush/rush_bookmark_seeds_2x.jpg";

import NarrowSection from "../../components/structure/NarrowSection";

import Emphasis from "../../components/Emphasis";

import BookmarkGroup from "../../components/BookmarkGroup";

const P = styled.p``;

const BookstoresWebsite = () => (
	<>
		<NarrowSection>
			<P>
				<Emphasis>TextbookRush had done bookmarks before,</Emphasis>
				shipping them with textbook orders to continue advertising their
				services and products even after a purchase had been made; but
				this time, the directive was different. One side of the bookmark
				would contain an interesting or funny concept or quote, and the
				goal was to create something that would resonate with customers
				so they’d use the bookmarks rather than throwing them away.
			</P>
		</NarrowSection>
		<BookmarkGroup
			images={[
				rushBookmarkGrouchoFront,
				rushBookmarkGrouchoBack1,
				rushBookmarkGrouchoBack2
			]}
			captions={["front", "back", "alt back"]}
		/>
		<NarrowSection>
			<P>
				<Emphasis>
					I was encouraged to explore a variety of styles,
				</Emphasis>
				with eye-catching colors and designs taking precedence over
				rigid alignment with existing branding. I was given (mostly)
				free reign to experiment, a welcome opportunity to step outside
				the box.
			</P>
			<P>
				A couple of my ideas, like the one below, involved a self-aware
				approach to the bookmark:
			</P>
		</NarrowSection>
		<BookmarkGroup
			images={[
				rushBookmarkXFront1,
				rushBookmarkXFront2,
				rushBookmarkXBack
			]}
			captions={["front", "alt front", "back"]}
		/>
		<NarrowSection>
			<P>
				<Emphasis>
					Just to convey the breadth of different concepts
				</Emphasis>{" "}
				that were considered, below are a few that didn’t make the cut:
				(1) “Night”, featuring a dryly humorous Steve Martin quote
				punctuated by bold lettering and rich colors that shift to match
				the transitional background; (2) “Plan A”, whose words could
				apply just as readily to the in-flux lives of college students
				as to the plot of a novel in the Western genre and whose rough,
				heavily typographic style was inspired by historic “Wanted”
				posters; and (3) “Plant”, a memorable piece of life advice
				brought to life through a carefully balanced asymmetrical block
				of text and a photo whose close-up nature emphasizes the
				disparity between the size and significance of a tiny seed.
			</P>
		</NarrowSection>
		<BookmarkGroup
			images={[rushBookmarkNight, rushBookmarkPlanA, rushBookmarkSeeds]}
			captions={["“Night”", "“Plan A”", "“Seeds”"]}
		/>
	</>
);

export default BookstoresWebsite;
