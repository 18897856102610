import styled, { css } from "styled-components";
import { mediaSingleProp } from "../styles/utilities";

const baseTitle = css`
	color: #333333;
	font-family: "Rajdhani", sans-serif;
	text-transform: uppercase;
`;

const H1 = styled.h1`
	${baseTitle}
	margin: 0 0 -21px 0;
	font-family: "Rajdhani", sans-serif;
	font-size: 125px;
	line-height: 0.92;
	text-transform: uppercase;
	${mediaSingleProp("margin-bottom", [
		[1000, "-17px"],
		[800, "-14px"],
		[600, "-10px"],
		[450, "-7px"]
	])}
	${mediaSingleProp("font-size", [
		[1000, "100px"],
		[800, "80px"],
		[600, "60px"],
		[450, "45px"]
	])}
`;

const ExcerptTitle = styled.h2`
	${baseTitle}
	margin: 20px 0 15px 0;
	font-size: 72px;
	line-height: 1;
	@media only screen and (max-width: 1000px) {
		margin: 15px 0 10px 0;
	}
	@media only screen and (max-width: 800px) {
		margin: 15px 0 12px 0;
		font-size: 60px;
	}
	@media only screen and (max-width: 600px) {
		font-size: 48px;
	}
	@media only screen and (max-width: 450px) {
		font-size: 36px;
	}
`;

const ExcerptSubtitle = styled.h3`
	margin: 0;
	font-family: "Special Elite", sans-serif;
	font-size: 28px;
	line-height: 1;
	font-weight: normal;
	${mediaSingleProp("font-size", [
		[800, "24px"],
		[600, "20px"],
		[450, "16px"]
	])}
`;

export { H1, ExcerptTitle, ExcerptSubtitle };
