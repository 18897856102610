import React from "react";
import Section from "./Section";
import WideContent from "./WideContent";

const WideSection = ({ children, className }) => (
	<Section className={className}>
		<WideContent>{children}</WideContent>
	</Section>
);

export default WideSection;
