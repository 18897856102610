import useMedia from "./useMedia";

const useStandardBreakpoints = () =>
	useMedia({
		xs: "(max-width: 450px)",
		sm: "(max-width: 600px)",
		md: "(max-width: 800px)",
		lg: "(max-width: 1000px)"
	});

export default useStandardBreakpoints;
