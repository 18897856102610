import React from "react";
import styled from "styled-components";
import image from "../images/checkmark@2x.png";
import { clear } from "../styles/utilities";

const Container = styled.div`
	${clear}
	:nth-child(n + 2) {
		margin-top: 15px;
	}
`;

const Image = styled.img`
	display: block;
	float: left;
	width: 30px;
	height: 30px;
`;

const BulletText = styled.span`
	display: block;
	padding-top: 6px;
	padding-left: 40px;
	font-family: "Special Elite", sans-serif;
	line-height: 1.45;
`;

const Bullet = ({ children }) => (
	<Container>
		<Image src={image} alt="Checkmark" />
		<BulletText>{children}</BulletText>
	</Container>
);

export default Bullet;
