import React from "react";
import styled from "styled-components";

import k12LogoOld from "../../images/projects/K12BookServices/k12_logo_old_2x.png";
import k12LogoNew from "../../images/projects/K12BookServices/k12_logo_new_2x.png";
import k12Flyers from "../../images/projects/K12BookServices/k12_flyers.jpg";
import k12BusinessCardFront from "../../images/projects/K12BookServices/k12_business-card_front_2x.jpg";
import k12BusinessCardBack from "../../images/projects/K12BookServices/k12_business-card_back_2x.jpg";

import NarrowSection from "../../components/structure/NarrowSection";
import WideSection from "../../components/structure/WideSection";
import Hero from "../../components/images/Hero";
import { Grid, GridItem } from "../../components/grids/WideGrid";
import { Link } from "react-router-dom";

import Emphasis from "../../components/Emphasis";
import FancyImage from "../../components/images/FancyImage";

import useStandardBreakpoints from "../../hooks/useStandardBreakpoints";

const P = styled.p``;

const Logo = styled(FancyImage)`
	padding: 18%;
	img {
		display: block;
		margin: 0 auto;
		width: 100%;
	}
`;

const BookstoresWebsite = () => {
	const which = useStandardBreakpoints();

	const perRow =
		{
			xs: 1,
			sm: 1
		}[which] || 2;

	return (
		<>
			<NarrowSection>
				<P>
					<Emphasis>
						For K12 Book Services, the name says it all.
					</Emphasis>{" "}
					The company provides an array of services to K-12 schools
					with a focus on buying and selling books. I was asked to
					refresh their brand, blending the professionalism K12 Book
					Services had become known for with the more distinctive,
					student-focused personality they were seeking.
				</P>
				<P>
					I crafted a new logo, matching the basic structure of the
					old but tweaking the color palette. The cold, hard black of
					the original made way for a warmer blue; and I added yellow,
					a color often associated with creativity, energy, and
					optimism, as an accent color.
				</P>
				<P>
					The old logo included no fewer than 4 different fonts, and
					the result was a bit messy. I limited myself to just one,
					Fredoka One, which has the straightforward forms of a fairly
					standard geometric, sans-serif font but with a hint of
					playfulness.
				</P>
			</NarrowSection>
			<WideSection>
				<Grid perRow={perRow}>
					<GridItem>
						<Logo src={k12LogoOld} caption="old K12 logo" />
					</GridItem>
					<GridItem>
						<Logo src={k12LogoNew} caption="new K12 logo" />
					</GridItem>
				</Grid>
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>I fleshed out the brand </Emphasis>
					with a set of design elements that could be used to
					distinguish K12 Book Services across various print materials
					and the website.
				</P>
				<P>
					In the flyers shown below, and on{" "}
					<Link to="/projects/k12_website">the website</Link>, I used
					a background modeled after a sheet of ruled paper, with the
					iconic vertical pink line and horizontal light blue lines:
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={k12Flyers} caption="flyers" />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>Other background elements included</Emphasis> a
					chalkboard texture in various colors (see the horizontal
					strips of color at the top of the flyers and the single,
					larger word in each headline) and illustrations of school
					supplies (scattered strategically around the margins of the
					flyers and used as a pattern of outlined shapes on the
					website and in the business cards below).
				</P>
				<P>
					Bold typography and color choices placed a strong emphasis
					on key words and headings.
				</P>
			</NarrowSection>
			<WideSection>
				<Grid perRow={perRow}>
					<GridItem>
						<FancyImage
							src={k12BusinessCardFront}
							caption="business card front"
						/>
					</GridItem>
					<GridItem>
						<FancyImage
							src={k12BusinessCardBack}
							caption="business card back"
						/>
					</GridItem>
				</Grid>
			</WideSection>
		</>
	);
};

export default BookstoresWebsite;
