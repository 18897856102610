import React from "react";
import styled from "styled-components";
import Hero from "../../components/images/Hero";
import rushSiteHome from "../../images/projects/TextbookRush/rush_site_home.jpg";
import rushSiteLogin from "../../images/projects/TextbookRush/rush_site_login.jpg";
import NarrowSection from "../../components/structure/NarrowSection";
import WideSection from "../../components/structure/WideSection";
import Emphasis from "../../components/Emphasis";
import { Grid, GridItem } from "../../components/grids/NarrowGrid";
import useMedia from "../../hooks/useMedia";
import icons from "../../data/icons";
import colors from "../../styles/colors";

const P = styled.p``;
const Image = styled.img`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	padding: 20%;
`;

const IconGrid = styled(Grid)`
	overflow: hidden;
	border: 1px solid ${colors.borderColor};
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	@media only screen and (max-width: 800px) {
		border-radius: 7px;
	}
`;

const IconGridItem = styled(GridItem)`
	@media only screen and (max-width: 450px) {
		:nth-child(2n + 2) {
			background-color: ${colors.backgroundColor};
		}
	}
	@media only screen and (min-width: 451px) and (max-width: 700px) {
		:nth-child(-2n + 4) {
			background-color: ${colors.backgroundColor};
		}
		:nth-child(5) {
			background-color: ${colors.backgroundColor};
		}
		:nth-child(7) {
			background-color: ${colors.backgroundColor};
		}
		:nth-child(2n + 10) {
			background-color: ${colors.backgroundColor};
		}
	}
	@media only screen and (min-width: 701px) {
		:nth-child(-2n + 6) {
			background-color: ${colors.backgroundColor};
		}
		:nth-child(2n + 7) {
			background-color: ${colors.backgroundColor};
		}
	}
`;

const TextbookRushWebsite = () => {
	const which = useMedia({
		xs: "(max-width: 450px)",
		sm: "(max-width: 700px)"
	});

	const perRow =
		{
			xs: 3,
			sm: 4
		}[which] || 6;

	return (
		<>
			<NarrowSection>
				<P>
					<Emphasis>
						TextbookRush.com needed more than a facelift.
					</Emphasis>{" "}
					Constructed piecemeal over the years as new features and
					products were launched and priorities changed, the textbook
					retailer’s e-commerce site needed a consistent, cohesive
					look - to be redesigned from a single, fresh perspective so
					customers were not disoriented by an unpredictable
					experience or turned off by unprofessional oddities and
					imperfections.
				</P>
				<P>
					I reorganized the layout, developing a clear visual
					hierarchy to establish eye flow for the user and place
					proper emphasis on content and features. Employing a
					wholistic design approach, I considered and refined all of
					the core design elements and the ways in which they were
					used.
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={rushSiteHome} caption="TextbookRush.com home page" />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>
						One set of elements that I took special care with
					</Emphasis>{" "}
					was the photography throughout the site. The existing
					standard was, well, fluid, and often leaned toward photos
					that telegraphed their stock photo status through flat-color
					backgrounds, oddball situations, and models with
					over-the-top expressions on their faces.
				</P>
				<P>
					I implemented a new standard, opting for realistic photos
					with relevant background contexts and a very specific set of
					aesthetic parameters with regard to color, lighting, etc. In
					the absence of in-house photography resources, I still
					wanted to portray realistic scenarios into which our college
					student audience could place themselves.
				</P>
			</NarrowSection>
			<WideSection>
				<Hero
					src={rushSiteLogin}
					caption="TextbookRush.com login page"
				/>
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>
						The existing site made extensive use of icons,
					</Emphasis>
					but no system was in place to clearly define a consistent
					look for these icons. I created a new set inspired by the
					originals and influenced by a modern, “flat” design style, a
					la Google. The color palette I chose was limited (for
					simplicity and cohesiveness) yet flexible, and
					semi-realistic by design. While I’d been asked to use more
					true-to-life color, the company’s top decision-makers tended
					to prefer bright, vivid color over ultra-realism. The
					resulting color palette was a compromise between these two
					requirements.
				</P>
				<P>
					The limited color palette, simple, geometric shapes, and
					mostly “flat” look (with added dimension in a select few
					cases, for effect) unified the icons.
				</P>
			</NarrowSection>
			<WideSection>
				<IconGrid perRow={perRow} gutter={"0"}>
					{icons.map(({ name, image }) => (
						<IconGridItem>
							<Image key={name} src={image} alt="Checkmark" />
						</IconGridItem>
					))}
				</IconGrid>
			</WideSection>
		</>
	);
};

export default TextbookRushWebsite;
