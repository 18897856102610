import React, { useState } from "react";
import "whatwg-fetch";
import Form from "./Form";
import StatusContext from "./StatusContext";

const EmailForm = ({ children, renderSubmitted }) => {
	const [error, setError] = useState();
	const [status, setStatus] = useState();

	const send = values => {
		setStatus("submitting");
		return fetch(
			"https://feqlsaxtrk.execute-api.us-east-1.amazonaws.com/MyStage/contact",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(values)
			}
		)
			.then(response => response.json())
			.then(({ errorMessage, success } = {}) => {
				if (errorMessage || !success) {
					setStatus();
					throw new Error(errorMessage || "Unknown Error");
				} else {
					setStatus("submitted");
					return true;
				}
			})
			.catch(err => setError(err.message || err));
	};

	return (
		<StatusContext.Provider value={{ status, errorMsg: error }}>
			<Form renderSubmitted={renderSubmitted} onSubmit={send}>
				{children}
			</Form>
		</StatusContext.Provider>
	);
};

export { StatusContext };

export default EmailForm;
