import React from "react";
import { Grid as BaseGrid, GridItem } from "./Grid";
import useStandardBreakpoints from "../../hooks/useStandardBreakpoints";

const Grid = ({ children, ...other }) => {
	const which = useStandardBreakpoints();

	const gutter =
		{
			xs: "10px",
			sm: "10px",
			md: "10px",
			lg: "15px"
		}[which] || "25px";

	return (
		<BaseGrid gutter={gutter} {...other}>
			{children}
		</BaseGrid>
	);
};

export { Grid, GridItem };
