import React from "react";
import styled from "styled-components";

// styles
import colors from "../../styles/colors";
import { mediaSingleProp } from "../../styles/utilities";

// components
import NarrowContent from "../structure/NarrowContent";
import { ExcerptTitle, ExcerptSubtitle } from "../headings";

const Container = styled.div`
	width: 100%;
	padding: 55px 0 70px;
	background-color: ${colors.backgroundColor};
	${mediaSingleProp("padding", [
		[1000, "45px 0 60px"],
		[800, "35px 0 40px"],
		[600, "25px 0 30px"]
	])}
`;

const ColorBar = styled.div`
	width: 70px;
	height: 6px;
	background-color: ${({ color }) => color};
	@media only screen and (max-width: 800px) {
		width: 50px;
		height: 4px;
	}
`;

const ExcerptHeader = ({ color, client, project }) => (
	<Container>
		<NarrowContent>
			<ColorBar color={color} />
			<ExcerptTitle>{client}</ExcerptTitle>
			<ExcerptSubtitle>{project}</ExcerptSubtitle>
		</NarrowContent>
	</Container>
);

export default ExcerptHeader;
