import React from "react";
import styled from "styled-components";
import { mediaSingleProp } from "../styles/utilities";
import projects from "../data/projects";
import Emphasis from "../components/Emphasis";

// components
import Header from "../components/bookends/Header";
import ProjectExcerpt from "../components/excerpts/ProjectExcerpt";
import Footer from "../components/bookends/Footer";
import NarrowSection from "../components/structure/NarrowSection";

const IntroSection = styled(NarrowSection)`
	margin: 100px 0;
	${mediaSingleProp("margin", [
		[1300, "90px 0"],
		[1000, "70px 0"],
		[800, "60px 0"],
		[600, "50px 0"],
		[450, "40px 0"],
	])}
`;

const P = styled.p``;

const Home = () => (
	<>
		<Header />
		<IntroSection>
			<P>
				<Emphasis>Hi there! I’m Josh,</Emphasis> a UI/UX Designer based
				out of the Columbus, Ohio area. Welcome to my personal site,
				where you’ll find samples of my work along with snippets of
				commentary on my thought process and design rationale for each
				project.
			</P>
			<P>Here are a few of my recent projects:</P>
		</IntroSection>
		{projects
			.filter(({ homeImage }) => homeImage)
			.map((project) => (
				<ProjectExcerpt {...project} />
			))}
		<Footer />
	</>
);

export default Home;
