import { css } from 'styled-components'
import { map } from 'lodash'

export const clear = css`
    :after {
        content: '';
        display: block;
        clear: both;
    }
`

export const mediaSingleProp = (propName, sizes = []) => (
    map(sizes, ([ size, val ]) => `
        @media only screen and (max-width: ${size}px) {
            ${propName}: ${val};
        }
    `).join('')
)