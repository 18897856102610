import React, { useContext } from "react";
import { FormContext } from "./Form";
import StandardField from "./Field";

const ConnectedInput = ({ name, Field = StandardField, ...uiProps }) => {
	const { data = {}, setFieldValue = () => null } = useContext(FormContext);
	const value = data[name];
	const onChange = e => setFieldValue(name, e.target.value);

	return <Field {...uiProps} {...{ name, value, onChange }} />;
};

export default ConnectedInput;
