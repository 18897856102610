import React from "react";
import styled from "styled-components";

// components
import NarrowSection from "../components/structure/NarrowSection";
import { H1 } from "../components/headings";
import Emphasis from "../components/Emphasis";

const P = styled.p``;

const About = () => (
	<>
		<NarrowSection>
			<H1>About me</H1>
		</NarrowSection>
		<NarrowSection>
			<P>
				<Emphasis>I’m Josh Narwold,</Emphasis> a UI/UX
				Designer based out of the Columbus, Ohio area.
			</P>
			<P>
				As a boy with creative aspirations, I drew my own sports
				cards in pencil, wrote and designed newsletters about various
				subjects, and even tried my hand at auto body design (adding my
				own copy and graphics to make little ads in my sketchbook).
			</P>
			<P>
				Today that passion manifests itself in my work as a
				designer. Over the course of sixteen years in the field, I've
				poured myself
				into creating experiences that are both functionally useful and a pleasure for the consumer or user.
			</P>
		</NarrowSection>
	</>
);

export default About;
