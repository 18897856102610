import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Client from "./pages/Client";
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Project from "./pages/projects/Project";

import styled from "styled-components";
import Header from "./components/bookends/Header";
import Footer from "./components/bookends/Footer";
import projects from "./data/projects";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 320px;
	height: 100vh; /* Avoid the IE 10-11 min-height bug. */
`;

const SiteContent = styled.div`
	flex: 1 0 auto;
`;

class App extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/" component={Home} />
				<Container>
					<Header />
					<SiteContent>
						<Switch>
							<Route
								path="/clients/:client_name"
								component={Client}
							/>
							{projects.map(project => (
								<Route
									key={project.name}
									path={`/projects/${project.name}`}
									render={() => <Project {...project} />}
								/>
							))}
							<Route path="/projects" component={Projects} />
							<Route path="/about" component={About} />
							<Route path="/contact" component={Contact} />
						</Switch>
					</SiteContent>
					<Footer />
				</Container>
			</Switch>
		);
	}
}

export default App;
