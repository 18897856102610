import React from "react";
import { Link as RRLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/colors";

const Link = styled(RRLink)`
	display: block;
	overflow: hidden;
	border: 1px solid ${colors.borderColor};
	border-radius: 10px;
	color: ${colors.textColor};
	text-decoration: none;
	transition: box-shadow 0.15s ease-in-out;
	:hover,
	:focus {
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
		text-decoration: none;
	}
	@media only screen and (max-width: 800px) {
		border-radius: 7px;
	}
`;

const Image = styled.img`
	display: block;
	width: 100%;
	height: auto;
`;

const Label = styled.div`
	padding: 10px 15px;
	background-color: ${colors.backgroundColor};
	font-family: "Special Elite", sans-serif;
	line-height: 1.5;
	@media only screen and (min-width: 451px) and (max-width: 800px) {
		padding: 9px 13px;
	}
`;

const Title = styled.div`
	font-size: 18px;
`;

const Subtitle = styled.div`
	font-size: 15px;
`;

const ProjectThumb = ({ name, client, project, thumbImage, thumbImageAlt }) => (
	<Link to={`/projects/${name}`}>
		<Image src={thumbImage} alt={thumbImageAlt} />
		<Label>
			<Title>{client}</Title>
			<Subtitle>{project}</Subtitle>
		</Label>
	</Link>
);

export default ProjectThumb;
