import { each, mapValues, findKey } from "lodash";
import { useState, useEffect } from "react";

const useMedia = (
	queries = {},
	defaultName = "default",
	defaultState = false
) => {
	const [state, setState] = useState(defaultState);

	useEffect(() => {
		let mounted = true;

		const listeners = mapValues(queries, query => window.matchMedia(query));

		const onChange = () => {
			if (!mounted) return;
			const matchedKey = findKey(listeners, l => l.matches);
			setState(matchedKey || defaultName);
		};

		each(listeners, l => l.addListener(onChange));

		onChange();

		return () => {
			mounted = false;
			each(listeners, l => l.removeListener(onChange));
		};
	}, [queries]);

	return state;
};

export default useMedia;
