import styled from "styled-components";
import { mediaSingleProp } from "../../styles/utilities";

const ExcerptContainer = styled.div`
	margin: 100px 0;
	${mediaSingleProp("margin", [
		[1300, "90px 0"],
		[1000, "70px 0"],
		[800, "60px 0"],
		[600, "50px 0"],
		[450, "40px 0"]
	])}
`;

export default ExcerptContainer;
