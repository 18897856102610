import React from "react";
import HedronGrid from "hedron";

const Grid = ({ children, gutter, wrap, perRow, ...other }) => {
	const basis = perRow && `${Math.floor(100 / perRow)}%`;

	return (
		<div style={{ paddingTop: "1px" }}>
			<HedronGrid.Provider
				breakpoints={{
					xs: "-450",
					sm: "-600",
					md: "-800",
					lg: "-1000",
					xl: "-1300"
				}}
				padding={`${gutter}`}
			>
				<HedronGrid.Bounds
					margin={`-${parseInt(gutter.replace("px", "")) +
						1}px -${gutter} -${gutter} -${gutter}`}
					wrap={perRow || wrap ? true : false}
					{...other}
				>
					{React.Children.map(children, child =>
						React.cloneElement(child, { basis })
					)}
				</HedronGrid.Bounds>
			</HedronGrid.Provider>
		</div>
	);
};

const GridItem = ({ children, basis, ...props }) => {
	const flexSuffix = basis ? ` ${basis}` : "";
	const defaultFlex = basis ? "1 0" : "1 1";
	const flex = `${props.flex || defaultFlex}${flexSuffix}`;
	return (
		<HedronGrid.Box flex={flex} {...props}>
			{children}
		</HedronGrid.Box>
	);
};

export { Grid, GridItem };
