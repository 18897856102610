import React from "react";
import styled, { css } from "styled-components";
import colors from "../../styles/colors";

const baseStyles = css`
	width: 100%;
	padding: 10px 15px;
	border: 2px solid ${colors.borderColor};
	transition: border-color 0.15s ease-in-out;
	border-radius: 7px;
	outline: none;
	-webkit-appearance: none;
	color: inherit;
	font-family: inherit;
	font-size: 16px;
	font-weight: 300;
	:hover {
		border-color: #b4b4b4;
	}
	:focus {
		border-color: ${colors.primaryColor};
	}
	${({ hasError }) =>
		hasError &&
		css`
			&,
			&:hover,
			&:focus {
				border-color: #f44336;
			}
		`}
	@media only screen and (min-width: 1001px) {
		font-size: 18px;
	}
	@media only screen and (max-width: 800px) {
		padding: 8px 12px;
	}
`;

const Input = styled.input`
	${baseStyles}
`;

const Select = styled.select`
	${baseStyles}
`;

const TextArea = styled.textarea`
	${baseStyles}
	min-height: 100px;
	resize: vertical;
`;

const BaseInput = ({ type, value, ...domProps }) =>
	({
		textarea: (
			<TextArea {...domProps} value={value}>
				{value}
			</TextArea>
		),
		select: <Select {...domProps} value={value} />
	}[type] || <Input {...domProps} value={value} type={type} />);

export default BaseInput;
