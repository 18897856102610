import React from 'react'
import Header from './Header'

const Footer = () => (
    <Header colors={{
        background: '#f0f0f0',
        hover: '#555555',
        name: '#b4b4b4',
        role: '#b4b4b4',
        border: 'rgba(180,180,180,.4)',
        link: '#555555',
    }}></Header>
)
 
export default Footer;