import React from "react";
import styled from "styled-components";
import NarrowSection from "../components/structure/NarrowSection";
import { H1 } from "./headings";

const Deck = styled.div`
	font-family: "Special Elite", sans-serif;
`;
const Title = styled(H1)`
	margin-top: 15px;
`;

const ProjectHeading = ({ client, project }) => (
	<NarrowSection>
		<Deck>{client}</Deck>
		<Title>{project}</Title>
	</NarrowSection>
);

export default ProjectHeading;
