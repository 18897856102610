import rushIconBox from "../images/projects/TextbookRush/box-icon_2x.png";
import rushIconCalendar from "../images/projects/TextbookRush/calendar-icon_2x.png";
import rushIconCheckmark from "../images/projects/TextbookRush/checkmark-icon_2x.png";
import rushIconEquals from "../images/projects/TextbookRush/equals-icon_2x.png";
import rushIconLeaf from "../images/projects/TextbookRush/leaf-icon_2x.png";
import rushIconMagnify from "../images/projects/TextbookRush/magnify-icon_2x.png";
import rushIconPiggy from "../images/projects/TextbookRush/piggy-icon_2x.png";
import rushIconPrinter from "../images/projects/TextbookRush/printer-icon_2x.png";
import rushIconRibbon from "../images/projects/TextbookRush/ribbon-icon_2x.png";
import rushIconStopwatch from "../images/projects/TextbookRush/stopwatch-icon_2x.png";
import rushIconTablet from "../images/projects/TextbookRush/tablet-icon_2x.png";
import rushIconWallet from "../images/projects/TextbookRush/wallet-icon_2x.png";

export default [
	{
		name: "rush_icon_box",
		image: rushIconBox,
		imageAlt: "Shipping box icon"
	},
	{
		name: "rush_icon_calendar",
		image: rushIconCalendar,
		imageAlt: "Calendar icon"
	},
	{
		name: "rush_icon_checkmark",
		image: rushIconCheckmark,
		imageAlt: "Checkmark icon"
	},
	{
		name: "rush_icon_equals",
		image: rushIconEquals,
		imageAlt: "Shipping box icon"
	},
	{
		name: "rush_icon_leaf",
		image: rushIconLeaf,
		imageAlt: "Calendar icon"
	},
	{
		name: "rush_icon_magnify",
		image: rushIconMagnify,
		imageAlt: "Checkmark icon"
	},
	{
		name: "rush_icon_piggy",
		image: rushIconPiggy,
		imageAlt: "Piggy bank icon"
	},
	{
		name: "rush_icon_printer",
		image: rushIconPrinter,
		imageAlt: "Printer icon"
	},
	{
		name: "rush_icon_ribbon",
		image: rushIconRibbon,
		imageAlt: "Blue ribbon icon"
	},
	{
		name: "rush_icon_stopwatch",
		image: rushIconStopwatch,
		imageAlt: "Stopwatch icon"
	},
	{
		name: "rush_icon_tablet",
		image: rushIconTablet,
		imageAlt: "Tablet icon"
	},
	{
		name: "rush_icon_wallet",
		image: rushIconWallet,
		imageAlt: "Wallet icon"
	}
];
