import React from "react";
import Section from "./Section";
import NarrowContent from "./NarrowContent";

const NarrowSection = ({ children, className }) => (
	<Section className={className}>
		<NarrowContent>{children}</NarrowContent>
	</Section>
);

export default NarrowSection;
