import styled from "styled-components";
import Button from "../Button";

const ExcerptButton = styled(Button)`
	border-color: ${({ color }) => color};
	color: ${({ color }) => color};
	:hover {
		background-color: ${({ color }) => color};
	}
	:focus {
		box-shadow: 0 0 0 2px rgba(${({ colorRGB }) => colorRGB}, 0.35);
	}
`;

export default ExcerptButton;
