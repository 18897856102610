import React from "react";
import ProjectHeading from "../../components/ProjectHeading";
import styled from "styled-components";
import { mediaSingleProp } from "../../styles/utilities";

const ProjectContent = styled.div`
	${mediaSingleProp("margin-top", [
		[1000, "-17px"],
		[800, "-14px"],
		[600, "-11px"]
	])}
`;

const Project = ({
	pageComponent: PageComponent,
	client,
	project,
	...other
}) => (
	<>
		<ProjectHeading client={client} project={project} />
		<ProjectContent>
			<PageComponent {...other} />
		</ProjectContent>
	</>
);

export default Project;
