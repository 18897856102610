import styled from "styled-components";
import { mediaSingleProp } from "../../styles/utilities";

const NarrowContent = styled.div`
	width: 800px;
	margin: 0 auto;
	line-height: 1.72;
	@media only screen and (min-width: 1001px) {
		font-size: 18px;
	}
	@media only screen and (max-width: 1000px) {
		width: 87.5%;
		max-width: 800px;
	}
	@media only screen and (max-width: 800px) {
		width: 93.75%;
	}
	${mediaSingleProp("line-height", [
		[800, "1.6875"],
		[600, "1.625"],
		[450, "1.6"]
	])}
`;

export default NarrowContent;
