import React, { useState, useContext } from "react";
import { every } from "lodash";
import { StatusContext } from "./EmailForm";

const FormContext = React.createContext({});

const Form = ({
	children,
	onSubmit = () => {},
	errorMsg,
	renderSubmitted = () => null
}) => {
	const [data, setData] = useState({});
	const { status } = useContext(StatusContext);
	let formSubmitListeners = [];

	const setFieldValue = (name, val) =>
		setData({
			...data,
			[name]: val
		});

	const handleSubmit = e => {
		e.preventDefault();
		const validationResults = formSubmitListeners.map(listener =>
			listener()
		);
		console.log(validationResults);
		if (!every(validationResults)) return false;
		onSubmit(data);
		return false;
	};

	const validateOnFormSubmit = callback => {
		formSubmitListeners.push(callback);
		return () =>
			(formSubmitListeners = formSubmitListeners.filter(
				listener => listener !== callback
			));
	};

	return (
		<FormContext.Provider
			value={{
				data,
				setFieldValue,
				validateOnFormSubmit
			}}
		>
			{status === "submitted" ? (
				renderSubmitted()
			) : (
				<form onSubmit={handleSubmit}>{children}</form>
			)}
		</FormContext.Provider>
	);
};

export { FormContext };

export default Form;
