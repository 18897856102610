import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { clear, mediaSingleProp } from "../../styles/utilities";

const List = styled.ul`
	margin: 25px 0 0 0;
	padding: 0;
	border-top: 2px solid ${({ color }) => color};
	list-style: none;
	${clear}
	${mediaSingleProp("margin-top", [[600, "15px"]])}
`;

const ListItem = styled.li`
	display: block;
	float: left;
	padding: 0 10px;
	font-family: "Special Elite", sans-serif;
	:first-child {
		padding-left: 0;
	}
`;

const NavLink = styled(Link)`
    display: block;
    margin-top: -2px;
    padding: 7px 0 0 0;
    border-top: 2px solid transparent;
	transition: border-color .15s ease-out;
	outline: none;
    color: ${({ color }) => color}
	text-decoration: none;
	:hover, :focus {
		text-decoration: none;
	}
    :hover, :focus {
        border-top-color: ${({ color }) => color};
    }
`;

const Nav = ({ colors }) => {
	const NavItem = ({ to, children }) => (
		<ListItem>
			<NavLink to={to} color={colors.link}>
				{children}
			</NavLink>
		</ListItem>
	);

	return (
		<List color={colors.border}>
			<NavItem to="/projects">projects</NavItem>
			<NavItem to="/about">about</NavItem>
			<NavItem to="/contact">contact</NavItem>
		</List>
	);
};

export default Nav;
