import styled from "styled-components";
import colors from "../styles/colors";
import RouterLink from "./RouterLink";

const Button = styled(RouterLink)`
	display: inline-block;
	padding: 10px 15px;
	background-color: transparent;
	border: 2px solid ${colors.primaryColor};
	border-radius: 7px;
	color: ${colors.primaryColor};
	font-family: "Rajdhani", sans-serif;
	font-size: 28px;
	line-height: 1.3;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
	transition: all 0.15s ease-in-out;
	::-moz-focus-inner {
		border: 0;
	}
	:hover,
	:focus {
		text-decoration: none;
	}
	:hover {
		color: #ffffff;
		background-color: ${colors.primaryColor};
	}
	:focus {
		box-shadow: 0 0 0 2px rgba(29, 167, 231, 0.35);
	}
	@media only screen and (max-width: 800px) {
		padding: 8px 12px;
		font-size: 24px;
	}
	@media only screen and (max-width: 450px) {
		font-size: 20px;
	}
`;

export default Button;
