import React from "react";
import Field from "./Field";

const emailRegEx = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g;
const validateEmail = value => value && value.match(emailRegEx);

const EmailInput = props => (
	<Field type="text" {...props} validate={validateEmail} />
);

export default EmailInput;
