import React, { useContext } from "react";
import styled from "styled-components";
import StatusContext from "./StatusContext";
import Alert from "../Alert";

const FormAlert = styled(Alert)`
	margin-bottom: 70px;
`;

const FormError = () => {
	const { errorMsg } = useContext(StatusContext);
	return errorMsg ? <FormAlert type="error">{errorMsg}</FormAlert> : null;
};

export default FormError;
