import React from "react";
import { css } from "styled-components";
import FancyImage from "../images/FancyImage";

const imageStyles = css`
	
`;

const ExcerptHero = props => (
	<FancyImage {...props} imageStyles={imageStyles} />
);

export default ExcerptHero;
