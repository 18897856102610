import React from "react";
import styled from "styled-components";
import useStandardBreakpoints from "../hooks/useStandardBreakpoints";
import { Grid, GridItem } from "./grids/WideGrid";
import FancyImage from "./images/FancyImage";
import WideSection from "./structure/WideSection";

const BookmarksSection = styled(WideSection)`
	> div {
		max-width: 900px;
	}
`;

const BookmarkGroup = ({ images = [], captions = [] }) => {
	const which = useStandardBreakpoints();

	return (
		<BookmarksSection>
			{which === "xs" ? (
				<Grid>
					<GridItem flex="0 0 66.6%">
						<FancyImage src={images[0]} caption={captions[0]} />
					</GridItem>
					<GridItem flex="0 0 33.3%">
						<Grid direction="vertical">
							<GridItem>
								<FancyImage
									src={images[1]}
									caption={captions[1]}
								/>
							</GridItem>
							<GridItem>
								<FancyImage
									src={images[2]}
									caption={captions[2]}
								/>
							</GridItem>
						</Grid>
					</GridItem>
				</Grid>
			) : (
				<Grid>
					<GridItem>
						<FancyImage src={images[0]} caption={captions[0]} />
					</GridItem>
					<GridItem>
						<FancyImage src={images[1]} caption={captions[1]} />
					</GridItem>
					<GridItem>
						<FancyImage src={images[2]} caption={captions[2]} />
					</GridItem>
				</Grid>
			)}
		</BookmarksSection>
	);
};

export default BookmarkGroup;
